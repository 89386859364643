<div class="jumbotron jumbotron-fluid mt-ncs">
  <div class="container">
    <div class="d-flex flex-wrap">
      <div>
        <h1 class="display-3">DSpace 7</h1>
        <p class="lead">Acceso a la producción científico-académica del ITBA, resultado de sus actividades de investigación y docencia, en cumplimiento de la Ley 26.899 de Repositorios Digitales Institucionales de Acceso Abierto.
Bienvenidos al repositorio institucional del ITBA, en donde encontrarán nuestra producción académica - científica, en cumplimiento de la Ley 26.899 de Repositorios Digitales Institucionales de Acceso Abierto.</p>
      </div>
    </div>
  </div>
</div>
