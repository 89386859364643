<div class="background-image-container mt-ncs">
  <div class="container">
    <div class="jumbotron jumbotron-fluid">
      <div class="d-flex flex-wrap">
        <div>
          <h1 class="display-3">Repositorio Institucional</h1>
	  <p class="lead">Bienvenidos al repositorio institucional del ITBA, en donde encontrarán nuestra producción académica - científica, en cumplimiento de la Ley 26.899 de Repositorios Digitales Institucionales de Acceso Abierto.</p>
        </div>
      </div>
    </div>
  </div>
  <picture class="background-image">
    <!-- source type="image/webp" srcset="assets/dspace/images/banner.webp 2000w, assets/dspace/images/banner-half.webp 1200w, assets/dspace/images/banner-tall.webp 768w" -->
   <source type="image/webp" srcset="assets/dspace/images/banner.webp 2000w, assets/dspace/images/banner.webp 1200w, assets/dspace/images/banner.webp 768w"> 
   <source type="image/jpg" srcset="assets/dspace/images/banner.jpg 2000w, assets/dspace/images/banner.jpg 1200w, assets/dspace/images/banner.jpg 768w">
    <!-- source type="image/jpg" srcset="assets/dspace/images/banner.jpg 2000w, assets/dspace/images/banner-half.jpg 1200w, assets/dspace/images/banner-tall.jpg 768w" -->
    <img alt="" [src]="'assets/dspace/images/banner.jpg'"/><!-- without the []="''" Firefox downloads both the fallback and the resolved image -->
  </picture>
  <small class="credits"></small>
</div>
